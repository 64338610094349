import React from 'react';  
import { connect } from "react-redux"
import  styles from "./awards-popup.module.css";
import {AwardsPopupSchema} from "../../../../../schemas/prompts/awards/ukResidents/awards"
import {hideUkHcpAwardsApplicationPopup} from "../../../../../actions/ukResidents/hcp/awards/awards"
import Editor from "../../../../Editor"
import {constructUkHcpAwardsApplicationFormEmailHtml, submitAwardApplicationFormUkHcp} from "../../../../../controllers/ukResidents/hcp/mailer"
import {isNullUndefined, isEmailValid} from "../../../../../util"
import CircularProgress from "@material-ui/core/CircularProgress"

class Popup extends React.Component {
    constructor(props){
        super(props);
        this.reader = new FileReader();
        this.state = {
            name: "",
            role: "",
            institution: "",
            address: "",
            email: "",
            phone: "",
            intendedCongress: {
                ECE: false,
                ESPE: false,
            },
            eligibilityConfirmation: false,
            personalDataConsent: false,
            publicAwardDisclosal: false,
            abstract: "",
            cv: "",
            maximumFileSizeExceeded: false,
            wasValidated: false,
            error: null,
            loading: false,
        }
    }

    closePopup = () => {
        let popupConfig = new AwardsPopupSchema()
        this.props.hideUkHcpAwardsApplicationPopup(popupConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
        this.closePopup()
    }

    onTextChange = ({target: {name, value}}) => {
        this.setState({[name]: value})
    }

    checkFileSizeLimit = () => {
        const {abstract, cv} = this.state;
        if(abstract !== ""){
            let totalSize = (parseFloat(abstract.size)/1024/1024);
            if(totalSize > 3){
                this.setState({maximumFileSizeExceeded: true})
                return;
            }
        }

        if(cv !== ""){
            let totalSize = (parseFloat(cv.size)/1024/1024);
            if(totalSize > 3){
                this.setState({maximumFileSizeExceeded: true})
                return;
            }else{
                this.setState({maximumFileSizeExceeded: false})
                return
            }
        }

        if(abstract !== "" && cv !== ""){
            let totalSize = (parseFloat(abstract.size)/1024/1024) + (parseFloat(cv.size)/1024/1024);
            if(totalSize > 3){
                this.setState({maximumFileSizeExceeded: true})
                return;
            }else{
                this.setState({maximumFileSizeExceeded: false})
                return
            }
        }else{
            this.setState({maximumFileSizeExceeded: false})
            return
        }
    }

    handleFileLoaded = (e, name, file) => {
        let result = e.target.result;
        this.setState({[name]: {file: result, name: name, type: file.type, size: file.size, extension: file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name}}, () => this.checkFileSizeLimit()) 
    };

    onFileChange = (e) => {
        const fieldName = e.target.name;
        const file = e.target.files[0];
        if(isNullUndefined(file)){
            return
        }
        this.reader.onloadend = (e) => this.handleFileLoaded(e, fieldName, file);
        this.reader.readAsDataURL(file)
    }

    onRadioChange = (e, name) => {
        let newIntendedCongress = {...this.state.intendedCongress}
        Object.keys(newIntendedCongress).forEach((item) => {
            if(item !== name){
                newIntendedCongress[`${item}`] = false
            }else{
                newIntendedCongress[`${item}`] = e.target.checked
            }
        })
        this.setState({intendedCongress: newIntendedCongress})
    }

    onCheckboxChange = e => {
        this.setState({[e.target.name]: e.target.checked})
    }

    clearForm = () => {
        let cleared = {
            name: "",
            role: "",
            institution: "",
            address: "",
            email: "",
            phone: "",
            intendedCongress: {
                ECE: false,
                ESPE: false,
            },
            eligibilityConfirmation: false,
            personalDataConsent: false,
            publicAwardDisclosal: false,
            abstract: "",
            cv: "",
            maximumFileSizeExceeded: false,
            wasValidated: false,
            error: null,
            loading: false,
        }
        this.setState({...this.state, ...cleared})
    }

    isEmpty = (items) => {
        if(items.includes("")){
            return true;
        }
        return false;
    }

    submitForm = async(okCallback) => {
        const {name, role, institution, address, email, phone, intendedCongress, eligibilityConfirmation, personalDataConsent, publicAwardDisclosal, abstract, cv, maximumFileSizeExceeded, loading} = this.state;

        if(loading) return
        
        this.setState({wasValidated: true, loading: true, error: null})

        if(this.isEmpty([name, role, institution, address, email, phone, abstract, cv])){
            this.setState({error: {response: {data: "Please fill in all required fields"}}, loading: false})
            return
        }

        if(!isEmailValid(email)){
            this.setState({error: {response: {data: "Email is invalid."}}, loading: false})
            return
        }

        if(!intendedCongress.ECE && !intendedCongress.ESPE){
            this.setState({error: {response: {data: "Please select an intended congress."}}, loading: false})
            return
        }

        if(!eligibilityConfirmation){ 
            this.setState({error: {response: {data: "Please confirm that you meet the eligibility criteria."}}, loading: false})
            return
        }

        if(!personalDataConsent){
            this.setState({error: {response: {data: "Please consent to Neurocrine UK storing your prsonal data for the purpose of administering the award."}}, loading: false})
            return
        }

        if(!publicAwardDisclosal){
            this.setState({error: {response: {data: "Please consent to any award being disclosed publicly by Neurocrine UK as part of the company's transparency commitment."}}, loading: false})
            return
        }

        if(maximumFileSizeExceeded){
            this.setState({error: {response: {data: "Maximum file size exceeded. Choose files with smaller sizes."}}, loading: false})
            return
        }
        
        let data = {
            subject: "UK Residents Health Care Professionals - Awards Application",
            html: constructUkHcpAwardsApplicationFormEmailHtml({name, role, institution, address, email, phone, intendedCongress, eligibilityConfirmation, personalDataConsent, publicAwardDisclosal, abstract, cv}),
            attachment: {cv, abstract}
        }

        try {
            await submitAwardApplicationFormUkHcp(data)
            this.clearForm()
 
            if(typeof okCallback === "function"){
                await okCallback()
            }
            this.closePopup()
        }catch(e){
            this.setState({error: e, loading: false})
        }

        this.setState({loading: false, wasValidated: false})
    }

    render() {  
        const { show, award, okCallback, closeCallback, okLabel, closeLabel} = this.props.popupConfig;
        const {name, role, institution, address, email, phone, intendedCongress, eligibilityConfirmation, personalDataConsent, publicAwardDisclosal, abstract, cv, maximumFileSizeExceeded, wasValidated, error} = this.state;

        if(!show) return <React.Fragment />

        return (  
            <div className={styles.popup}>  
            <div className={styles.popup_inner}>  
            
            

            <div className="row">
                <div className="col-12">
                <Editor type={"text"} name={"name"} label={"Name"} value={name} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && name.length < 1} />
                </div>
                <div className="col-12">
                  <Editor type={"text"} name={"role"} label={"Role"} value={role} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && role.length < 1} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"institution"} label={"Institution"} value={institution} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && institution.length < 1} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"address"} label={"Address"} value={address} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && address.length < 1}  />
                </div>
                <div className="col-12">
                   <Editor type={"text"} name={"email"} label={"Email"} value={email} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && !isEmailValid(email)}  />
                </div>
                <div className="col-12">
                  <Editor type={"text"} name={"phone"} label={"Telephone Number"} value={phone} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && phone.length < 1}  />
                </div>
                <div className="col-12">
                    Intended congress <br/>
                  
                     <Editor type={"radio"} name={"intendedCongress"} value={intendedCongress.ECE} checked={intendedCongress.ECE === true} onChange={e => this.onRadioChange(e, "ECE")}  error={wasValidated && (Object.keys(intendedCongress).filter(key => intendedCongress[`${key}`]) === undefined)[0]}
                     label={
                        <span>
                         ECE</span>} />
                     
                    <Editor type={"radio"} name={"intendedCongress"} value={intendedCongress.ESPE} checked={intendedCongress.ESPE === true} onChange={e => this.onRadioChange(e, "ESPE")}  error={wasValidated && (Object.keys(intendedCongress).filter(key => intendedCongress[`${key}`]) === undefined)[0]} 
                    label={
                        <span>
                          ESPE</span>}/>
                </div>
                <div className="col-12 mb-2">
                    <Editor type='checkbox' id='eligibilityConfirmation' name='eligibilityConfirmation' value={eligibilityConfirmation} onChange={this.onCheckboxChange} label={
                              <span>
                                I confirm that I meet the eligibility criteria for the Early Career Investigator Award and, if successful, am available to attend for the duration of the congress
                              </span>}  error={wasValidated && !eligibilityConfirmation}/>
                </div>
                <div className="col-12 mb-2">
                    <Editor type='checkbox' id='personalDataConsent' name='personalDataConsent' value={personalDataConsent} onChange={this.onCheckboxChange} label={
                              <span>
                                  In order to administer this award, Neurocrine UK will need to store your curriculum vitae, including contact details, until such time as the award has been made. Once the award has been made, Neurocrine UK will delete your personal data. Your personal data will not be used for any purpose other than administration of the award. Please indicate your consent for Neurocrine UK to store your personal data. If you do not provide this consent, you will not be eligible to be considered for the award.
                              </span>} error={wasValidated && !personalDataConsent}  />
                </div>

                <div className="col-12 mb-2">
                    <Editor type='checkbox' id='publicAwardDisclosal' name='publicAwardDisclosal' value={publicAwardDisclosal} onChange={this.onCheckboxChange} label={
                              <span>
                                  I consent to any award being disclosed publicly by Neurocrine UK as part of the company’s transparency commitment
                              </span>} error={wasValidated && !publicAwardDisclosal}  />
                </div>
         
                <div className="col-12">
                <span style={{color: maximumFileSizeExceeded && "red"}}>File uploads should not exceed 3mb</span>
                  <br/> Upload Abstract
                    <Editor type={"file"} name={"abstract"} label={"Upload Abstract"}  required={true} onFileChange={this.onFileChange} variant={"outlined"} accept={".pdf,.doc,.docx"} error={wasValidated && abstract === ""} />
                </div>
                <div className="col-12">
                   Upload CV
                    <Editor type={"file"} name={"cv"} label={"Upload CV"}  required={true} onFileChange={this.onFileChange} variant={"outlined"} accept={".pdf,.doc,.docx"} error={wasValidated && cv === ""} />
                </div>
            </div>
            {error && (
                <div className="col-12">
                    <small className="text-danger">
                    {`${error.response ? error.response.data : "Something went wrong, please try again"}`}
                    </small>
                </div>
            )}
            <div className="text-center mt-3">
            <button className={"custom_btn dark bordered_btn"} onClick={async () => await this.submitForm(okCallback)}>
                {this.state.loading ? <CircularProgress size={20}/> : okLabel}
            </button>

            <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                {closeLabel}
            </button>
            </div>
            
            </div>  
            </div>  
        );  
    }
}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = () => {
    return {
        hideUkHcpAwardsApplicationPopup
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)