import React from 'react';  
import { connect } from "react-redux"
import  styles from "./disclaimer-popup.module.css";
import {DisclaimerPopupSchema} from "../../../../../schemas/prompts/disclaimers/ukResidents/hcp"
import {hideUkResidentsHcpDisclaimerPopup} from "../../../../../actions/ukResidents/hcp"

class Popup extends React.Component {
    constructor(props){
        super(props);
        this.closeConfig = new DisclaimerPopupSchema()
    }

    closePopup = () => {
        this.props.hideUkResidentsHcpDisclaimerPopup(this.closeConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        this.closePopup()
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
    }

    okHandler = async (okCallback) => {
        this.closePopup()
        if(typeof okCallback === "function"){
            await okCallback()
        }
    }

    render() {  
        const { show, message, okCallback, closeCallback, okLabel, closeLabel} = this.props.popupConfig;

        if(!show) return <React.Fragment />

        return (  
            <div className={styles.popup}>  
            <div className={styles.popup_inner}>  
            <p>
                {message}
            </p> 
            <button className={"btn"} onClick={async () => await this.okHandler(okCallback)}>
                {okLabel}
            </button>

            <button  style={{marginLeft: '10px'}} className={"btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                {closeLabel}
            </button>  
            </div>  
            </div>  
        );  
    }
}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = () => {
    return {
        hideUkResidentsHcpDisclaimerPopup
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)