import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import { useSelector, useDispatch} from "react-redux"
import styles from "../../../components/header.module.css"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import classnames from "classnames";
import { window } from 'browser-monads';
import disableScroll from 'disable-scroll';
import { Icon } from 'react-icons-kit'
import {search} from 'react-icons-kit/fa/search'
import UkResidentsHcpDisclaimerPopup from "../../prompts/disclaimers/ukResidents/hcp/disclaimer-popup"
import UkResidentsHcpApplyOnlinePopup from "../../prompts/careers/ukResidents/hcp/apply-online"
import UkResidentsHcpEmailFriendPopup from "../../prompts/careers/ukResidents/hcp/email-friend"
import UkResidentsHcpAwardsApplicationPopup from "../../prompts/awards/ukResidents/hcp/awards-popup"
import {isNullUndefined} from "../../../util"
import {ukHcpSearchTermOnchange} from "../../../actions/ukResidents/hcp/util/search_onchange"
import {linkedin} from 'react-icons-kit/fa/linkedin'
import {twitter} from 'react-icons-kit/fa/twitter'
import {document} from "browser-monads"
import StraplineBanner from "../../_shared/topStrip/topstrip"

const UkResidentsHcpQuery = graphql`
query HeaderUkResidentsHcpQuery {
  allContentfulMenuItem {
    edges {
      node {
        slug
        position
        title
        description {
          content {
            content {
              value
            }
          }
        }
        spaceId
        default_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        cards_only_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        tabs_for_files_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        country_information_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        awards_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        current_vacancies_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
      }
    }
  }
}
`

const Header = (props) => {
  const globalState = useSelector(state => state);
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const [menuItemIsActive, setmenuItemIsActive] = useState(null)

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setPrevScrollpos(currentScrollPos)
    setVisible((prevScrollpos === currentScrollPos))
  };

  const submitSearch = term => {
    navigate(`/UkResidents/HCP/search?q=${term}`);
    dispatch(ukHcpSearchTermOnchange(""))
  }

  const searchOnchange = e => {
    dispatch(ukHcpSearchTermOnchange(e.target.value))
  }

  const searchKeyPress = e => {
    if(e.key === 'Enter'){
      submitSearch(globalState.ukHcpSearchTerm)
    }
  }

  const toggleClass = () => setIsActive(!isActive)

  const scrollPreventionOnActiveNav = () => {
    // if(isActive){
    //   document.body.style.overflow = 'hidden'
    // } else{
    //   document.body.style.overflow = 'auto'
    // }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  // scrollPreventionOnActiveNav();

  const sortMenuItemByPosition = (a, b) => {
    if(parseInt(a.node.position) > parseInt(b.node.position)){
      return 1;
    }
    if(parseInt(a.node.position) < parseInt(b.node.position)){
      return -1
    }
    return 0;
  }

  const sortSubMenus = (a, b) => {
    if(parseInt(a.position) > parseInt(b.position)){
      return 1;
    }
    if(parseInt(a.position) < parseInt(b.position)){
      return -1
    }
    return 0;
  }

  const compileSortedSubMenus = (parentMenuSlug, allSubMenuTemplates, isMobile) => {
    
    let pages = [];

    for(let [templateName, submenuArray] of Object.entries(allSubMenuTemplates)){

      if(!isNullUndefined(submenuArray)){
        
        for(let submenu of submenuArray){
          if(submenu.parentMenu.slug === parentMenuSlug){
            let path = ""
            switch(templateName){
              case "currentVacanciesTemplateSubMenus":
                path = `/UkResidents/HCP/Careers/${submenu.slug}`;
                break;
              case "awardsTemplateSubMenus":
                path = `/UkResidents/HCP/awards/${submenu.slug}`;
                break;
              default:
                path = `/UkResidents/HCP/${submenu.slug}`;
                break;
            }
            pages.push({...submenu, pagePath: path})
          }
        }

      }

    }

    if(isMobile){
      return pages.sort(sortSubMenus).map(item => {
        return <Link key={item.slug} to={item.pagePath}><li className={`${styles.top_nav_btns}`}>{item.title}</li></Link>
      })
    }

    return pages.sort(sortSubMenus).map(item => {
      return <Link key={item.slug} to={item.pagePath}><li className={`${styles.top_nav_btns}`}>{item.title}</li></Link>
    })
  }

  const query = useStaticQuery(UkResidentsHcpQuery);

  const menuItemEdges = query.allContentfulMenuItem.edges.filter(edge => edge.node.spaceId === process.env.GATSBY_UK_RESIDENTS_HCP_SPACE_ID);
  const setActiveMenuItem = id => {
    setmenuItemIsActive(id)
  }
  return (  
    <div>
  
    <header className={`${styles.header} ${isActive ? "active"  : '' } ${classnames("header blue", {
      "nav_active": !visible
    })}`}>
       <div className={`strapbanner ${isActive ? "active" : ""}`}>
      <StraplineBanner />
      </div>
     {globalState.ukResidentsHcpDisclaimerPopup.show && <UkResidentsHcpDisclaimerPopup popupConfig={globalState.ukResidentsHcpDisclaimerPopup} />}
     {globalState.ukHcpApplyOnlinePopup.show && <UkResidentsHcpApplyOnlinePopup popupConfig={globalState.ukHcpApplyOnlinePopup} />}
      {globalState.ukHcpEmailFriendPopup.show && <UkResidentsHcpEmailFriendPopup popupConfig={globalState.ukHcpEmailFriendPopup} />}
    {globalState.ukHcpAwardsApplicationPopup.show && <UkResidentsHcpAwardsApplicationPopup popupConfig={globalState.ukHcpAwardsApplicationPopup} />}

      <div className={`${styles.hide_on_mobile} ${styles.nav_wrap} row`}>
      <div className={styles.logo_section} >
      <Link to="/UkResidents/HCP/home">
      <span className={`${styles.logo} sticky--logo`}></span>
      </Link>
     
      </div>
      <div className={`${styles.main_nav} main_nav_wrap`} >
          <div className={`${styles.top_section_nav} hide_on_sticky`}>
              <ul>
              <li><Link to="/UkResidents/Landing">Home</Link></li>
                <li><Link to="/UkResidents/HCP/contact-us">Contact us</Link></li>
                {/* <li className={`search_content ${searchIsActive ? "active":""}`}>
                <button className="search__btn" aria-label="submit search"><Icon size={25} icon={search}  onClick={() => setSearchIsActive(!searchIsActive)}  /></button>
                <span className="search">
                 <input type="text" className="search__input" aria-label="search" placeholder="enter your search" onChange={searchOnchange} value={globalState.ukHcpSearchTerm} onKeyPress={searchKeyPress} />
                  <button className="search__btn" aria-label="submit search" onClick={() => submitSearch(globalState.ukHcpSearchTerm)} >Search</button>
                </span>

                </li> */}
              </ul>
          </div>
          <ul style={{float: 'right'}}>

          {menuItemEdges.sort(sortMenuItemByPosition).map(edge => {
                let slug = edge.node.slug
                let title = edge.node.title;
                
                let description = edge.node.description.content[0].content[0].value;
     
            const content = edge.node.description.content[0].content
            if(content.length > 1) {
              let contentArr = []

              for(let i=0; i<content.length;i++){
               
                contentArr.push(content?.[i]?.value)
              }

              description = contentArr.join("");
             

            }
            description = description?.replace(/_SUPERSCRIPTTM_/g, "<sup>&#174;</sup>");
            console.log(description, "Description")
                
                let allSubMenuTemplates = {
                  defaultTemplateSubMenus: edge.node.default_template,
                  cardsOnlyTemplateSubMenus: edge.node.cards_only_template,
                  tabsForFilesTemplateSubMenus: edge.node.tabs_for_files_template,
                  countryInformationTemplateSubMenus: edge.node.country_information_template,
                  awardsTemplateSubMenus: edge.node.awards_template,
                  currentVacanciesTemplateSubMenus: edge.node.current_vacancies_template,
                }
                return (
                  <li className={styles.primary_dropdown} key={slug}>
                    {title}
                  <div className={styles.dropdown_wrap}>
                    <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                          <div className={styles.first_circle}></div>
                          <div className={styles.second_circle}></div>
                          <div className={styles.third_circle}></div>
                          <div className={styles.fourth_circle}></div>
                          <div className={styles.fifth_circle}></div>
                         
                          
                        </div>
                        <div className={"col-12 col-md-12"}>
                        <ul >

                        {compileSortedSubMenus(slug, allSubMenuTemplates, false)}
                
                    </ul>
                        </div>
                        </div>
                    </div>
                  </div>
                    
                  </li>
                )
              })}
          
            
          
          </ul>
      </div>
    </div>
  

{/* START MOBILE HEADER */}
    <div className={`${styles.hide_on_desktop} ${styles.mobile_header} ${isActive ? `${styles.active}` : ""} row`} >
      
      <div className={"col-6 col-md-3 col-lg-3" } style={{zIndex:'999999999', background:'#fff', padding:'15px'}}>
          <Link to="/UkResidents/HCP/home">
          <span className={styles.logo}></span>
          </Link>
     
      </div>

      <div className={"col-6 col-md-9 col-lg-9"} style={{zIndex:'999999999', background:'#fff', padding:'20px'}}>
        <div className={styles.header_toggle}>
          {/* <a className={`search_content ${searchIsActive ? "active":""}`}>
            <button className="search__btn" aria-label="submit search"><Icon size={25} icon={search}  onClick={() => setSearchIsActive(!searchIsActive)}  /></button>
            <span className="search">
              <input type="text" className="search__input" aria-label="search" placeholder="enter your search" onChange={searchOnchange} value={globalState.ukHcpSearchTerm} onKeyPress={searchKeyPress} />
              <button className="search__btn" aria-label="submit search" onClick={() => submitSearch(globalState.ukHcpSearchTerm)} >Search</button>
            </span>

          </a> */}
        
          <div className={styles.hamburger} onClick={() => toggleClass()} >
            <span className={styles.line}></span>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
          </div>
          
        </div>
     
      </div>
        {/* START MOBILE NAV */}

    <div className={`mobile-menu hide_on_desktop ${isActive ? "active" : ""}` } >
        <div className="mobile-menu_wrap">
 
      <ul>  
 
        {menuItemEdges.sort(sortMenuItemByPosition).map((edge, id) => {
          let slug = edge.node.slug
          let title = edge.node.title;
          let description = edge.node.description.content[0].content[0].value;
          let allSubMenuTemplates = {
            defaultTemplateSubMenus: edge.node.default_template,
            cardsOnlyTemplateSubMenus: edge.node.cards_only_template,
            tabsForFilesTemplateSubMenus: edge.node.tabs_for_files_template,
            countryInformationTemplateSubMenus: edge.node.country_information_template,
            awardsTemplateSubMenus: edge.node.awards_template,
            currentVacanciesTemplateSubMenus: edge.node.current_vacancies_template,
          }
          return (               
          
            <li onClick={() => setActiveMenuItem(id)} className={` ${(menuItemIsActive === id && !isNullUndefined(menuItemIsActive)) ? "":""}`}>{title}
                  <ul >
                    <li className="parent_li">

                    {compileSortedSubMenus(slug, allSubMenuTemplates, true)}        

                    </li>
                  </ul>
                </li>
          )
      
        })}
        <hr/>

      

      </ul>
      <ul className="mobile_nav_footer">
          <li className={styles.landing_page_menu_item}><Link to="/UkResidents/Landing" onClick={() => setIsActive(false)}>Home <span>|</span></Link></li>
          <li className={styles.landing_page_menu_item}><Link to="/UkResidents/HCP/contact-us" onClick={() => setIsActive(false)}>Contact us</Link></li>
         
        </ul>
      </div>
    </div>

  {/* END MOBILE NAV */}
    </div>
    {/* END MOBILE HEADER */}
    </header>
  

  </div>
  );
}


export default Header